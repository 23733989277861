.app{
  display: flex;
  flex-direction: column;

}

.header{
  background-color: #ffce6d;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container{
  width: 1200px;
  align-self: center;
}


.panel-information{
  display: flex;
  justify-content: "center";
  flex-direction: row;
}

.headline-mobile{
  text-align: center;
  padding: 0;
  margin: 0;
  display: none;
}
.headline-desktop{
  display: block;
} 


.login-button{
  margin-left: 10px;
  border : none;
  background-color: unset;
  color: white;
  font-size: 15pt;
  cursor: pointer;
}



.login-container .close{
  cursor: pointer;
}



.menu-button img{
  height: 30px;
  margin: 10px;
}

.menu-button{
  background-color: #f2f1ec;
  min-width: 68px;
  display: block;
  padding: 10px 20px 10px 20px;
  color: #291b25;
  border-radius: 100px;
  font-family: HalisR-SRegular;
  display: flex;
  text-decoration: none;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.menu-button:hover, .menu-button.active{
  background-color: #f4ca44;
  color: white;
  cursor: pointer;
}

.bottom-menu-bar{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.login-content{
  position: relative;
  width: 500px;
  height: 400px;
  padding: 30px;
  background-color: rgba(255, 206, 109, 0.95);
  border-radius: 20px;
  flex-direction: column;
  border: none;
}

.login-container{
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1200px) {
  .container{
    width: 100%;    
  }

  .header{
    display: none;
  }

  .menu-button{
    color: #291b25;
    display: flex;
    font-size: 7pt;
    text-decoration: none;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
  }


  
  .bottom-menu-bar{
    background-color: rgba(255, 206, 109, 0.95); 
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }



  .bottom-menu-bar .menu-button{
    background-color: rgba(255, 206, 109, 0.95); 
    color: white;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .container{
    width: 100%;
  }
  .panel-information{
    flex-direction: column-reverse;
  }

  .headline-mobile{
    display: block;
  }
  .headline-desktop{
    display: none;
  } 

  .app{
    margin-bottom: 100px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .container{
    width: 100%;
  }
}




.header .container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.header .container .logo{
  object-fit: contain;
  height: 100%;
}

.content{
  background-color: #f7f7f7;
  height: 500px;
}




.header .menu-icon{
  height: 30px;
  margin: 10px;
}

.ask{
  font-family: "Shantell Sans", serif;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
  color: #f4ca44;
  text-shadow: rgba(0, 0, 0, 0.4) 1.46525px 1.46525px 0px
}

h1{
  font-family: "Shantell Sans", serif;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
  color: #291b25;
  text-shadow: rgba(0, 0, 0, 0.4) 1.46525px 1.46525px 0px
}

.content-helper{
  padding: 20px;
}

.ask-button{
  background-color: #f4ca44;
  color: white;
  padding: 10px 20px 10px 20px;
  border-radius: 100px;
  font-family: HalisR-SRegular;
  border: 3px solid white;
  cursor: pointer;
}

.send-document-button{
  background-color: white;
  color: #f4ca44;
  display: flex;
  padding: 10px 20px 10px 20px;
  border-radius: 100px;
  font-family: HalisR-SRegular;
  border: 3px solid #f4ca44;
  cursor: pointer;
  
}

.close{
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  background-image: url("./assets//close.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.loginText{
  font-family: "Shantell Sans", serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 30pt;
  font-style: normal;
  color: white;
  display: inline-block;
  text-shadow: rgba(0, 0, 0, 0.4) 1.4652;
  margin: 10px;
  cursor: pointer;

}

.loginHeader{
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  color: white;
  
}

.loginText.current{
  text-decoration-line: underline;
  text-decoration-thickness: 10px;
}

.loginForm{
  display: flex; 
  flex-direction: column;
  padding: 10;
  margin-left: 30;
  margin-right: 30;
}

.loginForm input{
  padding: 10px;
  font-weight: 800;
  font-size: 15pt;
}

.asktextarea{
  padding: 10px;
  font-weight: 800;
  font-size: 15pt;
}

.message-box{
  padding: 10px;
  font-weight: 800;
  font-size: 15pt;
  background-color: white;
  border: 1px solid #f4ca44;
  flex-direction: row;
  display: flex;
  margin-right: 10px;
  flex: 1;
}

.message-area{
  display: flex;
  flex-direction: column;
  flex: 1;
  
}

.message-box textarea:focus{
  outline: none;
}

.message-box textarea{
  display: flex;
  flex:1;

  
  border: none;
  background-color: white;
  font-size: 15pt;
  font-weight: 800;
}

.send-button{
  background-color: white;
  background-image: url("./assets/buttons/send.png");
  color: white;
  padding: 10px 20px 10px 20px;
  width: 30px;
  height: 30px;
  font-family: HalisR-SRegular;
  border: 3px solid white;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.history{
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
  align-self: stretch;
}

.history-item{
  background-color: white;
  padding: 20px;
  padding-left: 20px ;
  padding-right: 20px ;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.history-item-title{
  flex: 1;
}

.history-item-close{
  color: white;
  background-color: red;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  border-radius: 20px;
}

.history-item{
  text-decoration: none;
  color: black;
  
}

.history-item:hover{
  background-color: #ffce6d;
  color: white;
}


.history-item:hover a{
  background-color: #ffce6d;
  color: white;
  

}

.history a{
  text-decoration: none !important; 
}

.chat-content-message{
  padding: 10px;
  background-color: blue;
  margin: 10px;
  color: white !important;
  border-radius: 20px;
  align-self: flex-start ;
}

.chat-content-message .me{
  background-color: rgba(255, 206, 109, 0.95);
}
body {
  margin: 0;
  padding: 0;
  font-family: HalisR-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.HalisR-Black { font-family: HalisR-Black; }
.HalisR-Bold { font-family: HalisR-Bold; }
.HalisR-Book { font-family: HalisR-Book; }
.HalisR-Light { font-family: HalisR-Light; }
.HalisR-ExtraLight { font-family: HalisR-ExtraLight; }
.HalisR-Medium { font-family: HalisR-Medium; }
.HalisR-Regular { font-family: HalisR-Regular; }
.HalisR-SBlack { font-family: HalisR-SBlack; }
.HalisR-SBold { font-family: HalisR-SBold; }
.HalisR-SExtraLight { font-family: HalisR-SExtraLight; }
.HalisR-SBook { font-family: HalisR-SBook; }
.HalisR-SLight { font-family: HalisR-SLight; }
.HalisR-SMedium { font-family: HalisR-SMedium; }
.HalisR-SRegular { font-family: HalisR-SRegular; }
.HalisR-SThin { font-family: HalisR-SThin; }
.HalisR-Thin { font-family: HalisR-Thin; }